@import "./init";

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/font-awesome/scss/font-awesome";

$swal2-font-size: $font-size-base;
$swal2-title-font-size: $font-size-lg;
$swal2-content-font-size: $font-size-base;
$swal2-input-font-size: $font-size-base;
$swal2-validationerror-font-size: $font-size-base;
$swal2-footer-font-size: $font-size-sm;
$swal2-confirm-button-font-size: $font-size-base;
$swal2-cancel-button-font-size: $font-size-base;
$swal2-toast-title-font-size: $font-size-lg;
$swal2-toast-content-font-size: $font-size-base;
$swal2-toast-input-font-size: $font-size-base;
$swal2-toast-validation-font-size: $font-size-base;
$swal2-toast-buttons-font-size: $font-size-base;
$swal2-toast-footer-font-size : $font-size-sm;
@import "../../node_modules/sweetalert2/src/sweetalert2";
.swal2-content code {
    font-weight: bold;
}

$bs-datetimepicker-btn-hover-bg: $gray-800;
$bs-datetimepicker-primary-border-color: $dropdown-border-color;
@import "../../node_modules/tempusdominus-bootstrap-4/src/sass/tempusdominus-bootstrap-4";
@import "../../node_modules/highlight.js/styles/darkula.css";

@import "../../node_modules/bootstrap-select/sass/bootstrap-select";

.bootstrap-select {
    width: auto !important;
    .dropdown-toggle {
        border-color: $input-border-color;
    }

    .dropdown-menu {
        min-width: 320px;
        li a {
            white-space: pre-wrap;
            word-break: break-word;
        }
    }
}

@include media-breakpoint-down(sm) {
    .form-inline {
        .bootstrap-select {
            width: 100% !important;
        }

    }
}

